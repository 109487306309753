import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useCookies } from "react-cookie";
import { Image } from "./image";
import { i18n } from "../i18n";
const headerLogoSrc = require("../images/header_logo.png");
const fbImgSrc = require("../images/footer_fb.png");
const igImgSrc = require("../images/footer_ig.png");
const lineImgSrc = require("../images/footer_line.png");
const spLineImgSrc = require("../images/footer_line_sp.png");
const mmImgSrc = require("../images/footer_mail_magazine.png");
const arrowImgSrc = require("../images/arrow.png");

export const Layout = ({
	children,
	hideHeaderPadding = false,
	isFirstView = false,
}) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [showLang, setShowLang] = useState(false);
	const [showReserveNow, setShowReserveNow] = useState(false);
	const [cookie, setCookie] = useCookies(["lang"]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setTimeout(() => setShowLang(true), 2000);
	}, []);

	useEffect(() => {
		if (cookie.lang) {
			i18n.changeLanguage(cookie.lang);
		} else {
			i18n.changeLanguage("ja");
		}
	}, [cookie, i18n.language]);

	const changeLang = (lang: string) => {
		i18n.changeLanguage(lang);
		setCookie("lang", lang);
	};

	const handleClick = () => {
		setMenuOpen(!isMenuOpen);
	};

	return (
		<>
			<div className={`header ${isFirstView ? "firstview" : ""}`}>
				<div className="pc md:block md:block">
					<div className="fixed flex justify-between font-lato w-full md:py-6 z-10">
						{isFirstView ? (
							<div>
								<div className="lang font-lato">
									<button
										className={i18n.language === "ja" ? "active" : ""}
										onClick={() => changeLang("ja")}
									>
										JP
									</button>
									<div className="divider" />
									<button
										className={i18n.language === "en" ? "active" : ""}
										onClick={() => changeLang("en")}
									>
										EN
									</button>
								</div>
							</div>
						) : (
							<div className="logo-wrapper">
								<Link to="/">
									<img src={headerLogoSrc.default} alt="logo" />
								</Link>
							</div>
						)}

						<div className="flex">
							<div className="menu-item-text px-6">
								<Link to="/about">ABOUT</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link to="/news">NEWS</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link to="/menu">MENU</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link
									to="https://rubiarestaurantbar.stores.jp/"
									target="_blank"
									rel="noopener"
								>
									SHOP
								</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link to="/press">PRESS</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link to="/stories">STORIES</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link to="/access">ACCESS</Link>
							</div>
							<div className="menu-item-text px-6">
								<Link to="/careers">CAREERS</Link>
							</div>
							{!isFirstView ? (
								<div>
									<div className="lang font-lato">
										<button
											className={i18n.language === "ja" ? "active" : ""}
											onClick={() => changeLang("ja")}
										>
											JP
										</button>
										<div className="divider" />
										<button
											className={i18n.language === "en" ? "active" : ""}
											onClick={() => changeLang("en")}
										>
											EN
										</button>
									</div>
								</div>
							) : (
								<></>
							)}
						</div>
					</div>

					<div className="fixed top-1/2 left-6 transform -translate-y-1/2 z-10">
						<div className="my-2">
							<a
								href="https://www.instagram.com/rubia_tokyo/"
								target="_blank"
								rel="noopener"
							>
								<Image filename="ig_icon.png" height={48} width={48} />
							</a>
						</div>
						<div className="my-2">
							<a
								href="https://www.facebook.com/rubiatokyo/"
								target="_blank"
								rel="noopener"
							>
								<Image filename="fb_icon.png" height={48} width={48} />
							</a>
						</div>
						<div className="my-2">
							<a
								href="https://liff.line.me/1645278921-kWRPP32q?openerPlatform=native&openerKey=urlSchema%3Aexternal&accountId=417buqtc#mst_challenge=iPjSNmiI2DmPuciC0x7nQaapwdHgJZFLqdYVoddstaU"
								target="_blank"
								rel="noopener"
							>
								<Image filename="line_icon.png" height={48} width={48} />
							</a>
						</div>
					</div>

					<div className="fixed top-1/2 right-0 transform -translate-y-1/2 z-10">
						<div className="my-2">
							<div className={`pc reserve-now ${showReserveNow ? "show" : ""}`}>
								{i18n.language === "ja" ? (
									<Image filename="reserve_now.png" height={105} width={105} />
								) : (
									<Image
										filename="reserve_now_en.png"
										height={105}
										width={105}
									/>
								)}
							</div>
							<a
								href="https://www.hotpepper.jp/strJ001264866/"
								onMouseEnter={() => setShowReserveNow(true)}
								onMouseLeave={() => setShowReserveNow(false)}
								target="_blank"
								rel="noopener"
								className="no-hover"
							>
								<Image filename="reserve_button.png" height={175} width={56} />
							</a>
						</div>
						<div className="my-2">
							<a
								href="http://eepurl.com/cOzVdz"
								target="_blank"
								rel="noopener"
								className="no-hover"
							>
								<Image
									filename="mail_magazine_button.png"
									height={175}
									width={56}
								/>
							</a>
						</div>
					</div>
				</div>

				<div className="sp">
					{showLang && (
						<div className="header-logo-wrapper">
							{isFirstView ? (
								<div className="lang white font-lato">
									<button
										className={i18n.language === "ja" ? "active" : ""}
										onClick={() => changeLang("ja")}
									>
										JP
									</button>
									<div className="divider" />
									<button
										className={i18n.language === "en" ? "active" : ""}
										onClick={() => changeLang("en")}
									>
										EN
									</button>
								</div>
							) : (
								<Link to="/">
									<img src={headerLogoSrc.default} alt="logo" />
								</Link>
							)}
						</div>
					)}

					<a onClick={handleClick}>
						<div
							className={`hamburger-button ${isMenuOpen && "open"} ${
								isFirstView && "white"
							}`}
						>
							<span className="top" />
							<span className="bottom" />
						</div>
					</a>

					<div className={`menu font-lato ${isMenuOpen && "show"}`}>
						<div className="lang black font-lato">
							<button
								className={`${i18n.language === "ja" ? "active" : ""}`}
								onClick={() => changeLang("ja")}
							>
								JP
							</button>
							<div className="divider" />
							<button
								className={`${i18n.language === "en" ? "active" : ""}`}
								onClick={() => changeLang("en")}
							>
								EN
							</button>
						</div>

						<div className="links">
							<Link to="/about">ABOUT</Link>
							<Link to="/news">NEWS</Link>
							<Link to="/menu">MENU</Link>
							<a
								href="https://rubiarestaurantbar.stores.jp/"
								target="_blank"
								rel="noopener"
							>
								SHOP
							</a>
							<Link to="/press">PRESS</Link>
							<Link to="/stories">STORIES</Link>
							<Link to="/access">ACCESS</Link>
							<Link to="/careers">CAREERS</Link>
							<a
								href="http://eepurl.com/cOzVdz"
								target="_blank"
								rel="noopener"
								className="no-hover"
							>
								MAIL MAGAZINE
							</a>
						</div>

						<div className="sns-links">
							<a
								href="https://www.instagram.com/rubia_tokyo/"
								target="_blank"
								rel="noopener"
							>
								<Image filename="ig_icon.png" height={48} width={48} />
							</a>
							<a
								href="https://www.facebook.com/rubiatokyo/"
								target="_blank"
								rel="noopener"
							>
								<Image filename="fb_icon.png" height={48} width={48} />
							</a>
							<a
								href="https://liff.line.me/1645278921-kWRPP32q?openerPlatform=native&openerKey=urlSchema%3Aexternal&accountId=417buqtc#mst_challenge=iPjSNmiI2DmPuciC0x7nQaapwdHgJZFLqdYVoddstaU"
								target="_blank"
								rel="noopener"
							>
								<Image filename="line_icon.png" height={48} width={48} />
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className={`${hideHeaderPadding ? "" : "md:pt-20 pt-14"}`}>
				{children}
			</div>

			<a
				className="sp fixed-reservation-button"
				href="https://www.hotpepper.jp/strJ001264866/"
				target="_blank"
				rel="noopener"
			>
				<div className="en font-lato">RESERVE</div>
				<div
					className={`ja ${i18n.language === "ja" ? "font-yu" : "font-lato"}`}
				>
					{i18n.language === "ja" ? "今すぐ予約する" : "Book now"}
				</div>
			</a>

			<div className="footer">
				<div className="inner">
					<div className="sns-links">
						<div className="logo-wrapper">
							<Link to="/">
								<Image filename="logo_footer.png" alt="logo_footer" />
							</Link>
						</div>
						<a
							href="https://www.instagram.com/rubia_tokyo/"
							target="_blank"
							rel="noopener"
						>
							<img src={igImgSrc.default} alt="ig" />
						</a>
						<a
							href="https://www.facebook.com/rubiatokyo/"
							target="_blank"
							rel="noopener"
						>
							<img src={fbImgSrc.default} alt="fb" />
						</a>
						<img className="pc" src={lineImgSrc.default} alt="line" />
						<a
							className="sp"
							href="https://liff.line.me/1645278921-kWRPP32q?openerPlatform=native&openerKey=urlSchema%3Aexternal&accountId=417buqtc#mst_challenge=iPjSNmiI2DmPuciC0x7nQaapwdHgJZFLqdYVoddstaU"
							target="_blank"
							rel="noopener"
						>
							<img src={spLineImgSrc.default} alt="line" />
						</a>
						<a href="http://eepurl.com/cOzVdz" target="_blank" rel="noopener">
							<img src={mmImgSrc.default} alt="mail magazine" />
						</a>
					</div>

					<div className="sns-links">
						<div className="logo-wrapper">
							<Link to="/">
								<Image filename="logo_footer_tahona.png" alt="logo_footer" />
							</Link>
						</div>
						<a
							href="https://instagram.com/tahona_tokyo/"
							target="_blank"
							rel="noopener"
						>
							<img src={igImgSrc.default} alt="ig" />
						</a>
						<a
							href="https://www.facebook.com/rubiatokyo/"
							target="_blank"
							rel="noopener"
						>
							<img src={fbImgSrc.default} alt="fb" />
						</a>
					</div>

					<div className="md:flex md:justify-between">
						<div>
							<div
								className={`location ${
									i18n.language === "ja" ? "font-yu" : "font-lato"
								}`}
							>
								<a
									href="https://g.page/RUBIA_Tokyo?share"
									target="_blank"
									rel="noopener"
								>
									{i18n.language === "ja" ? (
										<div>
											〒150-0042
											<br />
											東京都渋谷区宇田川町13-4 国際ビルC館{" "}
											<img
												src={arrowImgSrc.default}
												alt="map link"
												height="10px"
												width="10px"
												style={{ display: "inline-block" }}
											/>
										</div>
									) : (
										<div>
											Kokusai Bld.Ckan, 13-4, Udagawacho,
											<br />
											Shibuya Ku, Tokyo, 150-0042{" "}
											<img
												src={arrowImgSrc.default}
												alt="map link"
												height="10px"
												width="10px"
												style={{ display: "inline-block" }}
											/>
										</div>
									)}
								</a>
								{i18n.language === "ja" ? (
									<div className="flex items-start">
										<div>TEL |</div>
										<div className="ml-2">
											<div>
												RUBIA{" "}
												<a className="ml-2" href="tel:05032041744">
													03-6416-5253
												</a>
											</div>
											<div>
												TAHONA{" "}
												<a className="ml-2" href="tel:05032041744">
													03-6416-0353
												</a>
											</div>
										</div>
									</div>
								) : (
									<div className="flex items-start">
										<div>TEL |</div>
										<div className="ml-2">
											<div>
												RUBIA{" "}
												<a className="ml-2" href="tel:05032041744">
													+813-6416-5253
												</a>
											</div>
											<div>
												TAHONA{" "}
												<a className="ml-2" href="tel:05032041744">
													+813-6416-0353
												</a>
											</div>
										</div>
									</div>
								)}
								<div>
									<a href="mailto:info@rubia.co.jp">MAIL | info@rubia.co.jp</a>
								</div>
							</div>
						</div>
						<div className="divider sp" />
						<div className="font-lato grid grid-cols-2 md:gap-x-8 gap-y-4">
							<div className="link-text">
								<Link to="/about">ABOUT</Link>
							</div>
							<div className="link-text">
								<Link to="/news">NEWS</Link>
							</div>
							<div className="link-text">
								<Link to="/menu">MENU</Link>
							</div>
							<div className="link-text">
								<a
									href="https://rubiarestaurantbar.stores.jp/"
									target="_blank"
									rel="noopener"
								>
									SHOP
								</a>
							</div>
							<div className="link-text">
								<Link to="/press">PRESS</Link>
							</div>
							<div className="link-text">
								<Link to="/stories">STORIES</Link>
							</div>
							<div className="link-text">
								<Link to="/access">ACCESS</Link>
							</div>
							<div className="link-text">
								<Link to="/careers">CAREERS</Link>
							</div>
							<div className="link-text">
								<a
									href="https://casadesarasa.thebase.in/"
									target="_blank"
									rel="noopener"
								>
									<Image
										filename="casa_de_sarasa.png"
										alt="casa_de_sarasa"
										width={102}
									/>
								</a>
							</div>
							<div className="link-text">
								<a href="http://tacos.world" target="_blank" rel="noopener">
									<Image
										filename="takos_kyokai.png"
										alt="takos_kyokai"
										width={104}
									/>
								</a>
							</div>
						</div>
					</div>
					<div className="copyright">RUBIA All Rights Reserved.</div>
				</div>
			</div>
		</>
	);
};
