import React, { useState, useEffect } from 'react'
import { Waypoint } from 'react-waypoint'

export const ScrollAndShow = ({ children, delay = 0 }) => {
	const [show, setShow] = useState(false)

	const handleOnEnter = () => {
		setTimeout(() => {
			setShow(true)
		}, 500 + delay)
	}
	
	return (
		<Waypoint onEnter={handleOnEnter} bottomOffset="30%">
			<div className={show ? 'show-element' : 'hide-element'}>
				{children}
			</div>
		</Waypoint>
	)
}
