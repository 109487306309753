import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const Image = (props) => (
	<StaticQuery
		query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fixed {
									...GatsbyImageSharpFixed_noBase64
								}
              }
            }
          }
        }
      }
    `}

		render={data => {
			const image = data.images.edges.find(n => {
				return n.node.relativePath.includes(props.filename)
			})

			if (!image) {
				return null
			}

			if (props.width && props.width > 0) {
				image.node.childImageSharp.fixed.width = Number(props.width)
			}

			if (props.height && props.height > 0) {
				image.node.childImageSharp.fixed.height = Number(props.height)
			}

			return (
				<Img fixed={image.node.childImageSharp.fixed} alt={props.alt} />
			)
		}}
	/>
)
